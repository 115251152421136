import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { FormControl, FormLabel, Input, Button, Stack, useToast, useTheme, InputGroup, InputRightElement, IconButton, Checkbox } from "@chakra-ui/react";
import { useAuthentication } from "../../wrappers/UseAuthentication";
import { useSupplierRetailer } from "../../wrappers/SupplierRetailerContext";
import { usePageAccess } from "../../wrappers/PageAccessContext";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const SignIn = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const toast = useToast();
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [staySignedIn, setStaySignedIn] = useState(localStorage.getItem("staySignedIn") === "true");

  const { signIn } = useAuthentication();
  const { fetchUserAccess, resetContext: resetSupplierRetailerContext } = useSupplierRetailer();
  const { fetchPageAccess, resetContext: resetPageAccessContext,restrictedyn } = usePageAccess();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const autoSignIn = async () => {
      const ls_staySignedIn = localStorage.getItem("staySignedIn") === "true";
      if (ls_staySignedIn) {
        const ls_username = localStorage.getItem("username");
        const ls_password = localStorage.getItem("password");
        if (ls_username && ls_password) {
          setUsername(ls_username);
          setPassword(ls_password);
          if(restrictedyn){
            await signin(ls_username, ls_password, "/myProfile");
          }
          else{
            await signin(ls_username, ls_password, "/sales");
          }

        }
      } else {
        const ls_username = localStorage.getItem("username");
        const ls_password = localStorage.getItem("password");
        const ls_userid = localStorage.getItem('userid');
        const searchParams = new URLSearchParams(location.search);
        const redirect = searchParams.get("redirect");
        if (ls_username && ls_password) {
          if (redirect) {
            console.log("redirect=true");
            if(ls_userid){
              fetchPageAccess(ls_userid);
            }
            setUsername(ls_username);
            setPassword(ls_password);
            await signin(ls_username, ls_password, `/${redirect}`);
          }
        }
      }
    };
    autoSignIn();
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setFormIsSubmitting(true);
    resetSupplierRetailerContext();
    resetPageAccessContext();
    if(restrictedyn){
      await signin(username, password, "/myProfile");
    }
    else{
      await signin(username, password, "/sales");
    }
    
  };

  const signin = async (username, password, page) => {
    try {
      const response = await axios.post(`${apiUrl}/login/authenticate`, { username, password });
      setFormIsSubmitting(false);

      if (response.status === 201) {
        const userid = response.data.data[0].userid;
        const fname = response.data.data[0].fname;

        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
        localStorage.setItem("userid", userid);
        localStorage.setItem("fname", fname);

        await fetchUserAccess(userid);
        await fetchPageAccess(userid);
        toast({
          title: response.data.message,
          description: "Looks good!",
          status: "success",
          duration: 500,
          isClosable: true,
        });
        signIn();
        navigate(page);
      } else {
        toast({
          title: response.data.message,
          description: response.data.description,
          status: "error",
          duration: 8000,
          isClosable: true,
        });
      }
    } catch (error) {
      setFormIsSubmitting(false);
      const errMsg = error.response?.data?.data?.error || "Technical Error Occurred";
      toast({
        title: "Sign in Failed!",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleStaySignedIn = (checkboxValue) => {
    setStaySignedIn(checkboxValue);
    localStorage.setItem("staySignedIn", checkboxValue);
  };

  return (
    <FormControl maxW="400px" mx="auto" mt={8} p="4" borderWidth="1px" borderRadius="lg">
      <form onSubmit={handleSignIn}>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Username</FormLabel>
            <Input type="text" placeholder="Enter your username" value={username} onChange={(e) => setUsername(e.target.value)} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input type={showPassword ? "text" : "password"} placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} />
              <InputRightElement>
                <IconButton aria-label={showPassword ? "Hide password" : "Show password"} icon={showPassword ? <ViewIcon /> : <ViewOffIcon />} onClick={() => setShowPassword(!showPassword)} variant="ghost" />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl>
            <Checkbox isChecked={staySignedIn} onChange={(e) => handleStaySignedIn(e.target.checked)}>
              Stay signed in
            </Checkbox>
          </FormControl>
          <Button type="submit" bg={theme.colors.primary1} _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} color="white" isLoading={formIsSubmitting} loadingText="Signing In">
            Sign in
          </Button>
        </Stack>
      </form>
    </FormControl>
  );
};

export default SignIn;
